var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "estate-album base-container"
  }, [_c('div', {
    staticClass: "header-mol"
  }, [_vm._m(0), _c('el-button', {
    attrs: {
      "type": "primary",
      "icon": "el-icon-plus"
    },
    on: {
      "click": function click($event) {
        _vm.editDialogVisible = true;
      }
    }
  }, [_vm._v("发布视频")])], 1), _c('ul', {
    staticClass: "video-list"
  }, _vm._l(_vm.videoList, function (item) {
    return _c('li', {
      key: item.id
    }, [_c('div', {
      staticClass: "video-wrap",
      on: {
        "click": function click($event) {
          return _vm.playVideo(item);
        }
      }
    }, [_c('video', {
      attrs: {
        "poster": item.corverImage,
        "src": item.filePath,
        "id": "video-".concat(item.id)
      }
    }), !item.play ? _c('div', {
      staticClass: "play-btn"
    }, [_c('svg-icon', {
      attrs: {
        "icon-class": "play"
      }
    })], 1) : _vm._e()]), _c('h3', [_vm._v(_vm._s(item.title))]), _c('div', {
      staticClass: "sum"
    }, [_c('p', [_vm._v("创建人：" + _vm._s(item.inUserName))]), _c('p', [_vm._v("创建时间：" + _vm._s(item.inDate))])]), _c('div', {
      staticClass: "actions"
    }, [_c('div', {
      staticClass: "blue",
      on: {
        "click": function click($event) {
          return _vm.showEditDialog(item);
        }
      }
    }, [_c('i', {
      staticClass: "el-icon-edit-outline"
    }), _vm._v("编辑 ")]), _c('div', {
      on: {
        "click": function click($event) {
          return _vm.delVideo(item);
        }
      }
    }, [_c('i', {
      staticClass: "el-icon-delete"
    }), _vm._v("删除")])])]);
  }), 0), _c('edit-dialog', {
    attrs: {
      "visible": _vm.editDialogVisible,
      "options": _vm.activeItem
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.editDialogVisible = $event;
      },
      "success": _vm.getEstateVideoList
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "info"
  }, [_c('h3', {
    staticClass: "title"
  }, [_vm._v("视频管理")]), _c('p', {
    staticClass: "summary"
  }, [_vm._v(" 创建和维护项目相关视频，如品宣视频、项目介绍视频等综合类视频；单条视频最大建议控制在20M以内； ")])]);

}]

export { render, staticRenderFns }