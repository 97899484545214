<template>
  <el-dialog
    :title="`${formData.id ? '编辑' : '创建'}项目视频`"
    v-bind="$attrs"
    v-on="$listeners"
    append-to-body
    width="500px"
    top="50px"
    :close-on-click-modal="false"
    @close="resetForm"
    class="f-dialog"
  >
    <el-form :model="formData" ref="form" label-width="80px">
      <el-form-item
        label="视频标题"
        prop="title"
        :rules="[$formRules.required()]"
      >
        <el-input placeholder="请输入" v-model="formData.title"></el-input>
      </el-form-item>
      <el-form-item
        label="封面图"
        prop="corverImage"
        :rules="[$formRules.required()]"
      >
        <p class="tips">建议尺寸400px *300px，大小1M以内</p>
        <f-upload
          :type="2"
          accept="image/gif, image/jpeg, image/jpg, image/png, image/webp"
          :default-file-list="
            formData.corverImage ? [formData.corverImage] : []
          "
          @on-success="e => uploadSuccess(e, 'image')"
        ></f-upload>
      </el-form-item>
      <el-form-item
        label="上传视频"
        prop="filePath"
        :rules="[$formRules.required()]"
      >
        <div class="video-upload-wrap">
          <p class="tips">大小建议不超过20M</p>
          <p
            class="no-video-tips"
            v-if="!showVideoProgress && !formData.fileName"
          >
            <i class="el-icon-info"></i>未选择文件
          </p>
          <el-progress
            class="video-progress"
            :percentage="videoUploadProgress"
            :stroke-width="12"
            :width="100"
            v-if="showVideoProgress"
          ></el-progress>
          <p class="video-name" v-if="formData.fileName">
            <img src="../../../../../assets/video.png" />{{ formData.fileName }}
          </p>
          <f-upload
            :type="6"
            button-text="选择视频"
            accept="video/mp4,video/avi,video/mov,video/wmv"
            show-process
            :limit-size="300"
            @on-success="e => uploadSuccess(e, 'video')"
            @on-uploading="videUploading"
          ></f-upload>
          <div class="mask" v-if="showVideoProgress"></div>
        </div>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="resetForm">取 消</el-button>
      <el-button type="primary" @click="submitForm">保存</el-button>
    </span>
  </el-dialog>
</template>
<script>
const formData = {
  corverImage: "",
  fileName: "",
  filePath: "",
  fileType: "2",
  id: null,
  title: ""
};
import FUpload from "@/components/upload";
import {
  createEstateVideo,
  delEstateVideo,
  getEstateVideoList
} from "@/api/estate";
import rules from "@/utils/rules";
export default {
  components: { FUpload },
  props: {
    options: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      rules,
      fileList: [],
      formData: this.$deepClone(formData),
      channelList: [],
      videoUploadProgress: 0
    };
  },
  computed: {
    showVideoProgress() {
      return this.videoUploadProgress > 0 && this.videoUploadProgress < 100;
    }
  },
  watch: {
    options(val) {
      this.formData = this.$deepClone(val);
    }
  },
  methods: {
    videUploading([file]) {
      this.videoUploadProgress = file?.percent;
      if (file?.percent === 100) {
        this.formData.fileName = file.name;
      }
    },
    // 图片上传成功回调
    uploadSuccess([file], type) {
      if (type === "video") {
        this.formData.fileName = file?.name;
        this.formData.filePath = file?.url;
      } else {
        this.formData.corverImage = file?.url;
      }
    },
    // 保存
    submitForm() {
      this.$refs.form.validate(async valid => {
        if (!valid) return;
        const res = await createEstateVideo({
          ...this.formData,
          filePath: `file/${this.formData.filePath.split("/file/")[1]}`, // 后端要相对路径 删掉host
          estateId: this.$estateInfo.estateId
        });
        if (res) {
          this.$emit("success", res);
          this.resetForm();
        }
      });
    },
    // 重置表单
    resetForm() {
      this.$emit("update:visible", false);
      this.formData = this.$deepClone(formData);
      this.$refs.form.resetFields(); // 清空表单数据
    }
  }
};
</script>
<style scoped lang="scss">
.video-upload-wrap {
  position: relative;
  .mask {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    cursor: wait;
  }
}
.video-progress {
  width: 180px;
  margin-bottom: 6px;
}
.tips {
  color: #999;
  padding: 10px 0;
}
.no-video-tips {
  color: #666;
  i {
    color: #bbb;
    font-size: 16px;
    margin-right: 5px;
  }
}
.video-name {
  margin-bottom: 10px;
  color: $blue;
  @include flex-yc;
  img {
    width: 14px;
    height: 16px;
    margin-right: 10px;
  }
}
</style>
