<template>
  <div class="estate-album base-container">
    <div class="header-mol">
      <div class="info">
        <h3 class="title">视频管理</h3>
        <p class="summary">
          创建和维护项目相关视频，如品宣视频、项目介绍视频等综合类视频；单条视频最大建议控制在20M以内；
        </p>
      </div>
      <el-button
        type="primary"
        icon="el-icon-plus"
        @click="editDialogVisible = true"
        >发布视频</el-button
      >
    </div>
    <ul class="video-list">
      <li v-for="item of videoList" :key="item.id">
        <div class="video-wrap" @click="playVideo(item)">
          <video
            :poster="item.corverImage"
            :src="item.filePath"
            :id="`video-${item.id}`"
          ></video>
          <div class="play-btn" v-if="!item.play">
            <svg-icon icon-class="play"></svg-icon>
          </div>
        </div>
        <h3>{{ item.title }}</h3>
        <div class="sum">
          <p>创建人：{{ item.inUserName }}</p>
          <p>创建时间：{{ item.inDate }}</p>
        </div>
        <div class="actions">
          <div class="blue" @click="showEditDialog(item)">
            <i class="el-icon-edit-outline"></i>编辑
          </div>
          <div @click="delVideo(item)"><i class="el-icon-delete"></i>删除</div>
        </div>
      </li>
    </ul>
    <edit-dialog
      :visible.sync="editDialogVisible"
      :options="activeItem"
      @success="getEstateVideoList"
    ></edit-dialog>
  </div>
</template>

<script>
import EditDialog from "./_components/edit";

import { delEstateVideo, getEstateVideoList } from "@/api/estate";
export default {
  components: {
    EditDialog
  },
  data() {
    return {
      editDialogVisible: false,
      videoList: [],
      activeItem: {}
    };
  },
  created() {
    this.getEstateVideoList();
  },
  methods: {
    async delVideo({ id }) {
      const res = await delEstateVideo({ id });
      if (res) {
        this.$showSuccess("删除成功");
        this.getEstateVideoList();
      }
    },
    showEditDialog(item) {
      this.activeItem = { ...item, random: Date.now() };
      this.editDialogVisible = true;
    },
    //点击视频播放
    playVideo(item) {
      const video = document.getElementById(`video-${item.id}`);
      const index = this.videoList.findIndex(i => i.id === item.id);
      if (item.play) {
        video.pause();
        this.$set(this.videoList, index, { ...item, play: false });
      } else {
        video.play();
        this.$set(this.videoList, index, { ...item, play: true });
      }
    },
    async getEstateVideoList() {
      const res = await getEstateVideoList({
        pageNum: 1,
        pageSize: 100,
        estateId: this.$estateInfo.estateId
      });
      if (res) {
        this.videoList = res.list;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.video-list {
  padding-top: 30px;
  gap: 10px;
  @include flex-w;
  li {
    width: 206px;
    padding: 10px;
    border: 1px solid #f5f5f5;
    .video-wrap {
      position: relative;
      cursor: pointer;
      .play-btn {
        position: absolute;
        left: 50%;
        top: 50%;
        margin: -25px 0 0 -25px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 1px solid #fff;
        color: #fff;
        @include flex-xyc;
      }
    }
    video {
      width: 184px;
      height: 136px;
      background: #141414;
    }
    h3 {
      padding-top: 10px;
      @include text-clamp(2);
    }
    .sum {
      padding: 10px 0;
      p {
        font-size: 12px;
        color: #999;
      }
    }
    .actions {
      @include flex-ycb;
      div {
        color: #999;
        cursor: pointer;
        @include flex-yc;
        &:active {
          opacity: 0.75;
        }
        &.blue {
          color: $blue;
        }
        i {
          font-size: 18px;
          padding-right: 6px;
        }
      }
    }
  }
}
</style>
